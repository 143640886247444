import React from "react"
import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"
import General from "../../../utils/General";

class StatesTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      submissionID: props.submissionID
    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Detail',
        id: 'reason',
        accessor: state => General.toTitleCase(state.reason),
      },
      {
        Header: 'Message',
        id: 'message',
        accessor: state => {
          return (
              <div style={{whiteSpace: "pre-wrap"}}>{state.message}</div>
          )
        },
        width: 500,
      },
      {
        Header: "Status",
        id: "status",
        width: 150,
        accessor: state => {
          let className = ''
          if(state.amendment_status){
            return null
          }
          if(state.route === "green"){
            className = 'svg-icon-light-success'
          }else if(state.route === "red"){
            className = 'svg-icon-light-danger'
          }else if(state.route === "yellow"){
            className = 'svg-icon-light-yellow'
          }else if(state.route === "orange"){
            className = 'svg-icon-light-orange'
          }
          let route = (
              <span className={`svg-icon ${className}`}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                >
                  <circle fill="currentColor" cx="12" cy="12" r="8"></circle>
                </svg>
            </span>
          )

          if(["draft", "complete", "processing","rejected"].indexOf(state.status) > -1){
            route = null
          }

          return (
              <>
                <span className="text-dark text-hover-primary fs-6">
                  {route} {General.snakeCaseToTitleCase(state.status)}
                </span>
              </>
          )
        },
      },
      {
        Header: 'Update',
        id: 'created_at',
        accessor: state => {
          let updatedBy = "Revenue"
          if(state.company_member){
            let user = state.company_member.user
            updatedBy = `${user.first_name} ${user.last_name}`
          }

          return (
              <>
                <a className="text-dark fw-bolder text-hover-primary fs-6">
                  {updatedBy}
                </a>
                <span className="text-muted fw-bold text-muted d-block fs-7">
                  {moment(state.created_at).format('lll')}
                </span>
              </>
          )
        },
      },
    ]

    return columns
  }

  render() {
    let {
      submissionID,
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.States}?submission_id=${submissionID}`}
          showHeader={false}
          showFilter={false}
          noDataMessage={"No changes found"}
          title={''}
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
        />
      </>
    )

  }

}

export default withRouter(StatesTable);
